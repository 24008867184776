import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import Img from "react-cloudinary-lazy-image"
import CommentsFB from "../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Revistas = ({ data }) => {
  const Revista = data.strapi.revista.galeria
    .sort(
      (a, b) =>
        a.name.replace(/(.*)\.[^.]+$/, "$1") -
        b.name.replace(/(.*)\.[^.]+$/, "$1")
    )
    .map(x => (
      // <Img
      //   key={x.name}
      //   cloudName={"lagexpress"}
      //   imageName={`${x.provider_metadata.public_id}.jpg`}
      //   alt={x.alternativeText}
      //   fluid={{
      //     maxWidth: "auto",
      //   }}
      //   style={{
      //     height: "100%",
      //   }}
      // />
      <img
        key={x.name}
        data-sizes="auto"
        className="lazyload blur-up"
        src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${x.provider_metadata.public_id}`}
        data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto/${x.provider_metadata.public_id}`}
        alt={x.alternativeText ? x.alternativeText : ""}
      />
    ))

  return (
    <Layout>
      <GatsbySeo
        title={`La Gauchita Nº ${data.strapi.revista.numero}`}
        titleTemplate="%s | Eduardo Ceballos"
        description={`Revista La Gauchita Nº ${data.strapi.revista.numero}`}
        canonical={`https://eduardoceballos.com/revistas/${data.strapi.revista.uid}`}
        openGraph={{
          url: `https://eduardoceballos.com/revistas/${data.strapi.revista.uid}`,
          title: data.strapi.revista.titulo,
          description: data.strapi.revista.descripcion,
          images: [
            {
              url: data.strapi.revista.tapa ? data.strapi.revista.tapa.url : "",
              alt: data.strapi.revista.tapa ? data.strapi.revista.tapa.alt : "",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>{data.strapi.revista.numero}</h1>

      <div className="revista-render">{Revista}</div>

      <hr />
      <CommentsFB></CommentsFB>
    </Layout>
  )
}

export const query = graphql`
  query Revistas($id: ID!) {
    strapi {
      revista(id: $id) {
        uid
        numero
        tapa {
          url
          alternativeText
          provider_metadata
        }
        galeria {
          url
          name
          alternativeText
          provider_metadata
        }
      }
    }
  }

  fragment GatsbyImageSharpFluid_withWebp on ImageSharpFluid {
    base64
    aspectRatio
    src
    srcSet
    srcWebp
    srcSetWebp
    sizes
  }
`

export default Revistas
